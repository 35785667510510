import * as React from 'react';

const SvgComponent = (props: any) => {
  return (
    <svg
      width={16}
      height={16}
      {...props}
    >
      <title>{'arrow-up-16x16'}</title>
      <defs>
        <path
          d='M7.243 4.773a1 1 0 011.414 0h0l4.95 4.95a1 1 0 010 1.414h0a1 1 0 01-1.414 0L7.95 6.894l-4.243 4.243a1 1 0 01-1.414 0h0a1 1 0 010-1.414l4.95-4.95h0z'
          id='prefix__a'
        />
      </defs>
      <g
        fill='none'
        fillRule='evenodd'
      >
        <path d='M0 0h16v16H0z' />
        <mask
          id='prefix__b'
          fill='#fff'
        >
          <use xlinkHref='#prefix__a' />
        </mask>
        <use
          fill='#D8D8D8'
          xlinkHref='#prefix__a'
        />
        <g
          mask='url(#prefix__b)'
          fill='#FFFFFF'
        >
          <path d='M0 0h16v16H0z' />
        </g>
      </g>
    </svg>
  );
};

export default SvgComponent;
