import * as React from 'react';

export default () => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <g id='Layer_1'>
        <path
          className='st1'
          fill='white'
          d='M12,0.2C5.5,0.2,0.2,5.5,0.2,12S5.5,23.8,12,23.8S23.8,18.5,23.8,12S18.5,0.2,12,0.2z M21.3,16.7h-3.9
            c0.3-1.2,0.4-2.6,0.5-4.1h4.6C22.3,14.1,21.9,15.5,21.3,16.7z M1.6,12.7h4.5c0,1.4,0.2,2.8,0.5,4.1H2.7C2.1,15.5,1.7,14.1,1.6,12.7
            z M2.7,7.3h3.9c-0.3,1.2-0.4,2.6-0.5,4.1H1.6C1.7,9.9,2.1,8.5,2.7,7.3z M12.7,6V1.7c1.2,0.4,2.3,2,3.1,4.3H12.7z M16.1,7.3
            c0.3,1.2,0.5,2.6,0.5,4.1h-3.9V7.3H16.1z M11.3,1.7V6H8.3C9,3.6,10.2,2.1,11.3,1.7z M11.3,7.3v4.1H7.4c0-1.5,0.2-2.9,0.5-4.1H11.3z
             M7.4,12.7h3.9v4.1H7.9C7.6,15.5,7.5,14.1,7.4,12.7z M11.3,18v4.3c-1.2-0.4-2.3-2-3.1-4.3H11.3z M12.7,22.3V18h3.1
            C15,20.4,13.8,21.9,12.7,22.3z M12.7,16.7v-4.1h3.9c0,1.5-0.2,2.9-0.5,4.1H12.7L12.7,16.7z M17.9,11.3c0-1.4-0.2-2.8-0.5-4.1h3.9
            c0.6,1.2,1,2.6,1.1,4.1H17.9z M20.5,6h-3.5C16.6,4.4,16,3,15.2,2C17.4,2.7,19.2,4.1,20.5,6z M8.8,2C8.1,3,7.4,4.4,6.9,6H3.5
            C4.8,4.1,6.7,2.7,8.8,2z M3.5,18h3.5c0.5,1.6,1.1,3,1.9,3.9C6.7,21.3,4.8,19.9,3.5,18z M15.2,22c0.8-1,1.4-2.3,1.9-3.9h3.5
            C19.2,19.9,17.4,21.3,15.2,22z'
        />
      </g>
      <g id='Layer_2' />
    </svg>
  );
};
