import * as React from 'react';

export default ({ initialState = false } = {}) => {
  const [isOpen, setIsOpen] = React.useState(initialState);
  const toggle = () => {
    return setIsOpen(!isOpen);
  };
  const close = () => {
    return setIsOpen(false);
  };
  const open = () => {
    return setIsOpen(true);
  };

  return { isOpen, toggle, close, open };
};
