export const sizes = {
  fs100: '11px',
  fs200: '12px',
  fs300: '13px',
  fs400: '14px',
  fs500: '16px',
  fs600: '18px',
  fs700: '20px',
  fs800: '24px',
  fs900: '28px',
  fs1000: '30px',
  fs1250: '36px',
  fs2000: '52px',
};

export const weights = {
  fw500: 500,
  fw600: 600,
  fw700: 700,
};
