import styled from '@emotion/styled';

const smallPixelCount = 576;
const mediumPixelCount = 768;
const largePixelCount = 992;
const hugePixelCount = 1200;
// Small devices (landscape phones, 576px and up)
export const small = `${smallPixelCount}px`;

// Medium devices (tablets, 768px and up)
export const medium = `${mediumPixelCount}px`;

// Large devices (desktops, 992px and up)
export const large = `${largePixelCount}px`;

// Extra large devices (large desktops, 1200px and up)ß
export const huge = `${hugePixelCount}px`;

//only specifying max-widths because existing code already has the patter which works: @media (min-width: ${screenSizes.small}).
//max-width needs 1 less than above numbers.
export const mediaMaxWidthXtraSmall = `@media (max-width: ${
  smallPixelCount - 1
}px)`;
export const mediaMaxWidthSmall = `@media (max-width: ${
  mediumPixelCount - 1
}px)`;
export const mediaMaxWidthMedium = `@media (max-width: ${
  largePixelCount - 1
}px)`;
export const mediaMaxWidthLarge = `@media (max-width: ${hugePixelCount - 1}px)`;

export const HideSmallerThanSmall = styled.div`
  ${mediaMaxWidthXtraSmall} {
    display: none;
  }
`;

export const HideSmallerThanMedium = styled.div`
  ${mediaMaxWidthSmall} {
    display: none;
  }
`;

export const HideSmallerThanLarge = styled.div`
  ${mediaMaxWidthMedium} {
    display: none;
  }
`;

export const HideLargerThanLarge = styled.div`
  @media screen and (min-width: ${large}) {
    display: none;
  }
`;

export const HideMediumAndLarger = styled.div`
  @media screen and (min-width: ${medium}) {
    display: none;
  }
`;
