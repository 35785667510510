import * as React from 'react';

import { CompassTheme } from '@circleci/compass';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

import { trackAction } from '~/backplane/analytics';
import Globe from '~/components/icons/Globe';
import * as colors from '~/components/shared/colors';
import { setQueryParam, getQueryParams } from '~/components/shared/Link';
import * as typography from '~/components/shared/typography';
import useCloseOnClickOutside from '~/hooks/useCloseOnClickOutside';
import useOpenClose from '~/hooks/useOpenClose';
import styled from '~/styled';

import ArrowUp from './icons/ArrowUp';

const ButtonReset = styled.button`
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  outline: 0;
`;

const DropdownButton = styled(ButtonReset)`
  min-width: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.white};
  position: relative;
  font-weight: ${typography.weights.fw600};
  font-size: 14px;
`;

const DropdownReset = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Dropdown = styled(DropdownReset)`
  background: ${colors.white};
  color: ${colors.black200};
  padding: 5px 0px;
  margin-bottom: 7px;
  position: absolute;
  bottom: 100%;
  top: auto;
  width: 160px;
  left: 0;
  border-radius: 2px;
  z-index: 1;
  line-height: 1.5;
  text-align: left;
`;

interface DropdownItemProps {
  isSelected: boolean;
  theme: CompassTheme;
}

const DropdownItem = styled.li`
  padding: 3px 15px;
  display: block;
  background-color: ${(props: DropdownItemProps) => {
    return props.isSelected && props.theme.colors.blue600;
  }};
  color: ${(props: DropdownItemProps) => {
    return props.isSelected && props.theme.colors.white;
  }};

  &:hover {
    background-color: ${(props: DropdownItemProps) => {
      return !props.isSelected ? '#f5f5f5' : props.theme.colors.blue600;
    }};
    cursor: pointer;
  }
`;

const Container = styled.div`
  position: relative;
`;

const GlobeWrapper = styled.div`
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-right: 6px;
`;

const ArrowIconWrapper = styled.div`
  margin-left: 6px;
`;

const LanguageLink = styled.a(({ theme }) => {
  return `
    text-decoration: none;
    color: ${theme.colors.blue600};
  `;
});

const LanguageDropdown = () => {
  const router = useRouter(),
    queryParams = getQueryParams((router && router.asPath) || ''),
    returnTo = queryParams.get('return-to'),
    query = new Map(),
    currentPath = router.pathname;
  const intl = useIntl();
  if (returnTo) {
    query.set('return-to', returnTo);
  }
  const languages = [
    {
      display: 'English (US)',
      value: 'en',
      language: 'english',
      url: setQueryParam(`${currentPath}`, query),
      as: setQueryParam(`${currentPath}`, query),
      query,
    },
    {
      display: '日本語',
      value: 'ja',
      language: 'japan',
      url: setQueryParam(`${currentPath}?lang=ja`, query),
      as: setQueryParam(`/ja${currentPath}`, query),
      query,
    },
  ];

  const ref = React.useRef(null);
  const currentLanguage = intl.locale;
  const { isOpen, toggle, close } = useOpenClose({ initialState: false });
  useCloseOnClickOutside({ ref, onClose: close });

  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    value: string,
    language: string,
    display: string,
  ) => {
    close();
    if (value === currentLanguage) {
      e.preventDefault();
      return;
    }
    trackAction('clicked-footer', {
      menu: language,
      cta_text: display,
    });
  };

  const selectedItem = languages.find((x) => {
    return x.value === currentLanguage;
  });
  return (
    <Container
      data-cy='languageDropdown'
      ref={ref}
    >
      <DropdownButton onClick={toggle}>
        <GlobeWrapper>
          <Globe />
        </GlobeWrapper>
        {selectedItem && selectedItem.display}
        <ArrowIconWrapper>
          <ArrowUp />
        </ArrowIconWrapper>
      </DropdownButton>
      {isOpen ? (
        <Dropdown>
          {languages.map(({ value, language, display, url, as }, i) => {
            return (
              <Link
                href={url}
                key={`dropdown-${value}-${i}`}
                legacyBehavior
                as={as}
              >
                <LanguageLink
                  hrefLang={value}
                  data-cy={`languageDropdown-${value}`}
                  onClick={(e) => {
                    return handleClick(e, value, language, display);
                  }}
                >
                  <DropdownItem isSelected={value === currentLanguage}>
                    {display}
                  </DropdownItem>
                </LanguageLink>
              </Link>
            );
          })}
        </Dropdown>
      ) : null}
    </Container>
  );
};

export default LanguageDropdown;
