const wait = async (ms: number): Promise<void> => {
  let resolver: () => void = () => {
    return undefined;
  };
  const timeoutPromise = new Promise<void>((resolve) => {
    resolver = resolve;
  });
  setTimeout(() => {
    return resolver();
  }, ms);
  await timeoutPromise;
};

export default wait;
