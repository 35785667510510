import * as React from 'react';

interface Args {
  ref: React.RefObject<any>;
  onClose: () => void;
}
export default ({ onClose, ref }: Args) => {
  const handleOutsideClick = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      onClose();
    }
  };
  React.useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });
};
