import * as React from 'react';

import {
  trackPage as analyticsTrackPage,
  trackUser as analyticsTrackUser,
  trackAction as analyticsTrackAction,
  useAnalytics as usePackagedAnalytics,
} from '@circleci/analytics';
import 'isomorphic-unfetch';

import getIsEnterprise from '~/utils/getIsEnterprise';

interface APIMe {
  analytics_id: string;
  login: string;
  name: string;
  selected_email: string;
  first_vcs_authorized_client_id: string | null;
  privacy_optout: boolean;
  sign_in_count: number;
  num_projects_followed: number;
}

interface Me {
  id: string;
  login: string;
  name: string;
  selected_email: string;
  first_vcs_authorized_client_id: string | null;
  privacy_optout: boolean;
  sign_in_count: number;
  num_projects_followed: number;
}

const defaultMe = { login: null };

const mapper = ({
  analytics_id,
  name,
  login,
  selected_email,
  first_vcs_authorized_client_id,
  privacy_optout,
  num_projects_followed,
  sign_in_count,
}: APIMe): Me => {
  return {
    id: analytics_id,
    name,
    login,
    selected_email,
    first_vcs_authorized_client_id,
    privacy_optout,
    num_projects_followed,
    sign_in_count,
  };
};

const meInfo = () => {
  if (getIsEnterprise()) {
    return defaultMe;
  }
  return fetch(`https://circleci.com/api/v1/me`)
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        return defaultMe;
      }
    })
    .catch(() => {
      return defaultMe;
    });
};

const mapDefaultProperties = (me) => {
  const doc = document || {
    location: { pathname: 'unknown', href: 'unknown' },
    referrer: 'unknown',
    title: 'unknown',
  };
  return {
    path: doc.location.pathname,
    url: doc.location.href,
    referrer: doc.referrer,
    title: doc.title,
    user: me.login,
  };
};

export const trackPage: typeof analyticsTrackPage = async (
  name,
  properties,
) => {
  const me = await meInfo();
  const defaultProps = mapDefaultProperties(me);
  analyticsTrackPage(name, { ...defaultProps, ...properties });
};

export const trackAction: typeof analyticsTrackAction = async (
  name,
  properties,
  options,
) => {
  const me = await meInfo();
  const defaultProps = mapDefaultProperties(me);
  analyticsTrackAction(name, { ...defaultProps, ...properties }, options);
};

export const trackUser = async (properties) => {
  const me = await meInfo();
  const mappedMe = mapper(me);

  // The 'properties' object here is used twice bcoz we had to update
  // the trackUser function to use two params vs one that was used before.
  // In order to keep the change as minimal as possible, before we can confirm
  // that the user is being tracked correctly, we decided to store the param
  // being passed before under 'userData' object and then just pass in 'properties'
  // as the second param.
  const userData = { ...mappedMe, ...properties };
  analyticsTrackUser(userData, properties);
};

export const useAnalytics = () => {
  const [me, setMe] = React.useState<Me>();
  React.useEffect(() => {
    (async () => {
      return setMe(mapper(await meInfo()));
    })();
  }, []);
  //need to cast as any because historically on signup the segment user props
  // are snake_case but the props for Me for the rest of the system is camelCase
  usePackagedAnalytics(me as any);
};
